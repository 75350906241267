import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import { colors } from '@atlaskit/theme';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ReactComponent as Image } from 'assets/images/svg/notFound.svg';
import { ROUTE_PATHS } from 'settings';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7.75rem;
  white-space: nowrap;
  padding: 0 6.25rem 0 5rem;
  overflow: hidden;
  height: 100vh;
  svg {
    height: 100%;
  }
`;
const ButtonHolder = styled.div`
  margin-top: 3rem;
`;
const HeadingsWrapper = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const HeadingTitle = styled(Heading)`
  margin-bottom: 1rem;
  color: ${colors.N900};
`;
const HeadingText = styled(Heading)`
  margin-bottom: 1rem;
  color: ${colors.N500};
`;

export default function NotFoundPage(): JSX.Element {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <div>
        <HeadingsWrapper>
          <HeadingTitle level="h500">404 error</HeadingTitle>
          <Heading level="h900">Oooops!</Heading>
          <Heading level="h900">Page not found...</Heading>
          <div>
            <HeadingText level="h200">This page doesn’t exist or was removed.</HeadingText>
            <HeadingText level="h200">We suggest you back to home.</HeadingText>
          </div>
        </HeadingsWrapper>

        <ButtonHolder>
          <Button onClick={() => navigate(ROUTE_PATHS.ROOT)} appearance="primary">
            Back to Home page
          </Button>
        </ButtonHolder>
      </div>
      <Image />
    </Wrapper>
  );
}
