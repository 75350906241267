import { colors, ThemeProps } from '@atlaskit/theme';
import styled, { css } from 'styled-components/macro';

import { AvailabilityStatus, AvailabilityStatusTypes } from 'api';

export const WrapperCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const handleColorType = (status: AvailabilityStatusTypes | null): string => {
  switch (status) {
    case AvailabilityStatus.Workday:
      return colors.G400;
    case AvailabilityStatus.TimeOff:
      return colors.R500;
    default:
      return colors.N90;
  }
};

export const Dot = styled.span<{
  status: AvailabilityStatusTypes | null;
  width: number;
  height: number;
}>`
  margin-right: 4px;
  border-radius: 50%;
  display: inline-block;

  ${({ status, width, height }) => css`
    height: ${height}px;
    width: ${width}px;
    background-color: ${() => handleColorType(status)};
  `}
`;

export const Divider = styled.hr`
  border: 0;
  height: 0;
  border-top: 1px solid ${colors.N30A};
  border-bottom: 1px solid ${colors.N30A};
  margin: 1.5rem 0;
`;

export const Heading = styled.div<{ mixin: () => ThemeProps }>`
  ${(props) => props.mixin()};
`;

export const WrapperTabs = styled.div`
  margin: 2.5rem 0 1.5rem -0.5rem;
`;

export const textOverflowStyles = css`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
