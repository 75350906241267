import { COMPANY } from 'api';
import { appPathStrategy, getCompanyName } from 'utils';

const path = appPathStrategy(getCompanyName());

const dictionary = {
  [COMPANY.yalantis]: {
    title: 'YA.ME',
  },
  [COMPANY.sherp]: {
    title: 'Sherp.HRM',
  },
};

export const indexHtmlDictionary = dictionary[path];
