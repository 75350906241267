import { COMPANY } from 'api';

function getCompanyName() {
  const company = process.env.REACT_APP_COMPANY;
  if (!company) console.warn('Company has not been found in envs. fallback to default value');

  switch (company) {
    case COMPANY.yalantis:
      return COMPANY.yalantis;

    case COMPANY.sab:
      return COMPANY.sab;

    case COMPANY.temy:
      return COMPANY.temy;

    case COMPANY.sherp:
      return COMPANY.sherp;

    default:
      return COMPANY.sherp;
  }
}

export default getCompanyName;
