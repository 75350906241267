import { DateType } from 'api';

export function getPreviousRequestDate(): DateType {
  const year = new Date().getFullYear();
  const month = new Date().getMonth();

  switch (month) {
    case 0:
      return { year: year - 1, month: 12 };
    default: {
      return { year, month };
    }
  }
}
