import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { ErpContractors } from 'api';
import { useFetchErp } from 'hooks';

function useErpContractorsQuery(
  options?: UseQueryOptions<ErpContractors, Error>,
): UseQueryResult<ErpContractors, Error> {
  const authorisedFetch = useFetchErp();

  return useQuery<ErpContractors, Error>(
    'contractorsErp',
    async () => {
      const response = await authorisedFetch('/public/contractors', {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useErpContractorsQuery;
