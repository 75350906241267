import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { Contractor } from 'api';
import { useFetchContractorDirectory } from 'hooks';

function useProfileQuery(
  contractorId: string,
  options?: UseQueryOptions<Contractor, Error>,
): UseQueryResult<Contractor, Error> {
  const authorisedFetch = useFetchContractorDirectory();

  return useQuery<Contractor, Error>(
    ['profile', contractorId],
    async () => {
      const response = await authorisedFetch(`/contractors/id/${contractorId}`, {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useProfileQuery;
