import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { OvertimeUndertimeRequestsQueryParameters, OvertimeUndertimeResponseSuccess } from 'api';
import { useFetchPayments } from 'hooks';

function useOvertimeUndertimeRequestQuery(
  { year, month }: OvertimeUndertimeRequestsQueryParameters,
  options?: UseQueryOptions<OvertimeUndertimeResponseSuccess, Error>,
): UseQueryResult<OvertimeUndertimeResponseSuccess, Error> {
  const authorisedFetch = useFetchPayments();

  return useQuery<OvertimeUndertimeResponseSuccess, Error>(
    ['overtime_undertime_requests', month, year],
    async () => {
      const response = await authorisedFetch(`ext/over-under-times?year=${year}&month=${month}`, {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useOvertimeUndertimeRequestQuery;
