import { useCallback } from 'react';

import { useAuth } from 'hooks';

export default function useFetchPayments(withoutContentType?: boolean) {
  const { token, signOut } = useAuth();

  const authorisedFetch = useCallback(
    (url, options = {}) => {
      const { headers, ...rest } = options;

      const headersSettings = withoutContentType
        ? {
            Authorization: `authBearer ${token}`,
            ...headers,
          }
        : {
            'Content-Type': 'application/json',
            Authorization: `authBearer ${token}`,
            ...headers,
          };

      const response = fetch(`${process.env.REACT_APP_PAYMENTS_URL}/api/${url}`, {
        headers: {
          ...headersSettings,
        },
        ...rest,
      }).then((response) => {
        if (response.status === 401) {
          // something wrong with the token, logout!
          signOut();
          throw new Error('JWT Invalid, re-login please');
        }

        if (!response.ok) {
          return response.text().then((text) => {
            throw new Error(text);
          });
        }

        return response;
      });

      return response;
    },
    [signOut, token, withoutContentType],
  );

  return authorisedFetch;
}
