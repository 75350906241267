export const createdAtFormat = 'dd MMM yyyy';
export const createdAtFullTimeFormat = 'dd MMM yyyy HH:mm';
export const createdAtFormatWithoutYear = 'dd MMM';
export const requestFormDateFormat = 'D MMM YYYY';
export const timeOffUntilDateFormat = 'd MMM';
export const filterDateFormat = 'D MMM YYYY';
export const contractorPageDateFormat = 'dd MMM yyyy';
export const timeOffReportDateFormat = 'dd.MM';
export const createDateFormat = 'yyyy-MM-dd';
export const dateFieldPlaceholder = 'MM/DD/YYYY';
export const questionnaireListItemDateFormat = 'd MMM yyyy';
export const riskRadarTableDateFormat = 'dd.MM.yy';
export const compensationDateFieldFormat = 'DD/MM/YYYY';
export const overtimeUndertimeDateLabelFormat = 'MMMM, yyyy';
