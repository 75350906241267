import { colors } from '@atlaskit/theme';
import { ReactElement, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { ROUTE_PATHS } from 'settings';

const Wrapper = styled.div<{ withFixHeight: boolean }>`
  padding: 0 16px 55px 24px;
  background-color: ${colors.N20};
  ${({ withFixHeight }) =>
    withFixHeight
      ? css`
          height: calc(100vh - 68px);
        `
      : css`
          min-height: calc(100vh - 68px);
        `}
`;

interface PageProps {
  children: ReactNode;
}

function Page({ children }: PageProps): ReactElement {
  const { pathname } = useLocation();

  return (
    <Wrapper withFixHeight={pathname.includes(ROUTE_PATHS.TIME_TRACKING.LOGS.INDEX)}>
      {children}
    </Wrapper>
  );
}

export default Page;
