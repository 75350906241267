import { useMutation } from 'react-query';

import { useFetchContractorDirectory } from 'hooks';

function useNineBoxInformedMutation() {
  const authorisedFetch = useFetchContractorDirectory();

  const submitNineBoxInformed = useMutation<Promise<Response>, Error, { id: string }>(
    async ({ id }) => {
      const response = await authorisedFetch(`/assessments9box/notification/${id}`, {
        method: 'PUT',
        body: JSON.stringify({ active: false }), // { active: false } - default; { active: true } don`t use
      });

      return response.ok ? response : response.json();
    },
  );

  return { submitNineBoxInformed };
}
export default useNineBoxInformedMutation;
