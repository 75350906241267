import { UseQueryOptions, useQuery } from 'react-query';

import { CompanySettings } from 'api';
import { useFetchContractorDirectory } from 'hooks';

function useWorkLogSettingsQuery(options?: UseQueryOptions<CompanySettings, Error>) {
  const authorisedFetch = useFetchContractorDirectory();

  return useQuery<CompanySettings, Error>(
    `company-settings`,
    async () => {
      const response = await authorisedFetch(`/worklog_settings`, {
        method: 'GET',
      });

      const data: CompanySettingsResponse = await response.json();

      return adapter(data);
    },
    options,
  );
}

export default useWorkLogSettingsQuery;

type CompanySettingsResponse = {
  company_exception_days: {
    exception_holidays: string[];
    work_days: string[];
  } | null;
  projects_days_off:
    | {
        name: string;
        project_id: number;
      }[]
    | null;
  worklog_status_list: {
    id: number;
    name: string;
    is_open: boolean;
    is_approved: false;
    is_pending: false;
  }[];
};

const adapter = (data: CompanySettingsResponse): CompanySettings => {
  return {
    exceptionHolidays: data.company_exception_days?.exception_holidays || [],
    exceptionWorkdays: data.company_exception_days?.work_days || [],
    projectsDaysOff:
      data.projects_days_off?.map(({ name, project_id }) => ({
        name,
        projectId: project_id,
      })) || [],
    worklogStatusList: data.worklog_status_list.map((status) => ({
      id: status.id,
      name: status.name,
      isOpen: status.is_open,
      isApproved: status.is_approved,
      isPending: status.is_pending,
    })),
  };
};
