import { FileTypes } from 'api';
import { returnFileSize, validFileType, validFileTypeRequest } from 'utils';
import { MAX_FILE_SIZE } from 'validations';

function validateType(file: File): null | string {
  if (!file) {
    return null;
  }

  if (!validFileType(file.type)) {
    return `Files supported: JPEG, PNG`;
  }

  return null;
}

function validateTypeAndSize(file: File): null | string {
  if (!file) {
    return null;
  }

  if (!validFileType(file.type)) {
    return `Files supported: JPEG, PNG`;
  }

  if (returnFileSize(file.size) > MAX_FILE_SIZE) {
    return `Maximum size: ${MAX_FILE_SIZE}MB`;
  }

  return null;
}

function validateTypeAndSizeRequest(file: File): null | string {
  if (!file) {
    return null;
  }

  if (!validFileTypeRequest(file.type as FileTypes)) {
    return `Files supported: JPEG, PNG, PDF`;
  }

  if (returnFileSize(file.size) > MAX_FILE_SIZE) {
    return `Maximum size: ${MAX_FILE_SIZE}MB`;
  }

  return null;
}

export { validateTypeAndSize, validateTypeAndSizeRequest, validateType };
