import { FileTypes } from 'api';
import { SUPPORTED_FILE_FORMATS, SUPPORTED_FILE_FORMATS_REQUEST } from 'validations';

function validFileType(type: string): boolean {
  return SUPPORTED_FILE_FORMATS.includes(type);
}

function validFileTypeRequest(type: FileTypes): boolean {
  return SUPPORTED_FILE_FORMATS_REQUEST.includes(type);
}

export { validFileType, validFileTypeRequest };
