import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { YaMeRolesResponseSuccess } from 'api';
import { useFetchContractorDirectory } from 'hooks';

function useYaMeRolesQuery(
  options?: UseQueryOptions<YaMeRolesResponseSuccess, Error>,
): UseQueryResult<YaMeRolesResponseSuccess, Error> {
  const authorisedFetch = useFetchContractorDirectory();

  return useQuery<YaMeRolesResponseSuccess, Error>(
    'ya_me_roles',
    async () => {
      const response = await authorisedFetch(`/ya-me/roles`, {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useYaMeRolesQuery;
