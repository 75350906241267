import jwt_decode from 'jwt-decode';
import { ReactElement, useCallback, createContext, useContext, useReducer } from 'react';

import {
  ASSOCIATES_YA_ME_PAGE_KEY,
  AuthActions,
  authReducer,
  AuthState,
  initialAuthState,
  localStorageAccessKey,
  localStorageResourcesAccessKey,
  rolesAndPermissionsTabsAccessKey,
  YA_ME_INTERNAL_SYSTEM_CODE,
} from 'api';
import { notify } from 'components/notification';
import { ROUTE_PATHS } from 'settings';
import { remove, write } from 'utils';

interface ProvideAuth extends AuthState {
  signIn: (accessToken: string) => void;
  signOut: () => void;
}

const authContext = createContext<ProvideAuth>({
  ...initialAuthState,
  signIn: () => null,
  signOut: () => null,
});

export function ProvideAuth({ children }: { children: ReactElement }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth(): ProvideAuth {
  const [state, dispatch] = useReducer(authReducer, initialAuthState);

  const clearAccessToken = (type: AuthActions.LOGOUT): void => {
    remove(localStorageAccessKey);
    remove(localStorageResourcesAccessKey);
    remove(ASSOCIATES_YA_ME_PAGE_KEY);
    remove(rolesAndPermissionsTabsAccessKey);

    dispatch({
      type,
    });
  };

  const signOut = useCallback(() => clearAccessToken(AuthActions.LOGOUT), []);

  const signIn = useCallback(
    async (accessToken: string) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_CONTRACTOR_DIRECTORY_URL}/login`, {
          method: 'POST',
          body: JSON.stringify({
            token: accessToken,
          }),
          credentials: 'include',
        });

        const data = await response.json();

        const resources = await fetch(
          `${process.env.REACT_APP_CONTRACTOR_DIRECTORY_URL}/resources`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${data.token}`,
            },
          },
        ).then((response) => {
          if (response.status === 401) {
            // something wrong with the token, logout!
            dispatch({ type: AuthActions.LOGOUT });
            notify({ content: 'JWT Invalid, re-login please', type: 'info' });
            throw new Error('JWT Invalid, re-login please');
          }

          if (response.status === 402) {
            // something wrong with the token, logout!
            signOut();
            window.location.replace(ROUTE_PATHS.PAYMENT_REQUIRED.INDEX);

            throw new Error('your subscription expired');
          }

          if (!response.ok) {
            return response.text().then((text) => {
              throw new Error(text);
            });
          }

          return response;
        });

        const resourcesData = await resources.json();

        const { internalRoles, id } = jwt_decode(data.token);

        if (!internalRoles[YA_ME_INTERNAL_SYSTEM_CODE].length) {
          dispatch({ type: AuthActions.LOGOUT });
          notify({ content: 'JWT Invalid, no roles to sign in', type: 'info' });
          return;
        }

        write(localStorageAccessKey, data.token);
        write(localStorageResourcesAccessKey, resourcesData);

        dispatch({
          type: AuthActions.LOGIN,
          token: data.token,
          roles: internalRoles[YA_ME_INTERNAL_SYSTEM_CODE],
          resources: resourcesData,
          id,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [signOut],
  );

  return {
    ...state,
    signIn,
    signOut,
  };
}
