import { ToastContainer, Slide } from 'react-toastify';
import styled from 'styled-components/macro';

const StyledToastContainer = styled(ToastContainer)`
  top: 4.25rem;
  .Toastify__toast-icon {
    margin-right: 18px;
  }
  svg {
    fill: currentColor;
  }
`;

function NotificationsContainer() {
  return (
    <StyledToastContainer
      position="top-right"
      closeButton={false}
      autoClose={2000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
      draggable={false}
      transition={Slide}
      style={{ width: '400px' }}
    />
  );
}

export default NotificationsContainer;
