import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { CdRolesResponseSuccess } from 'api';
import { useFetchContractorDirectory } from 'hooks';

function useCdRolesQuery(
  options?: UseQueryOptions<CdRolesResponseSuccess, Error>,
): UseQueryResult<CdRolesResponseSuccess, Error> {
  const authorisedFetch = useFetchContractorDirectory();

  return useQuery<CdRolesResponseSuccess, Error>(
    'cd-roles',
    async () => {
      const response = await authorisedFetch('/roles', {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useCdRolesQuery;
