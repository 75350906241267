import { useCallback, useState } from 'react';

interface PopupHandlers {
  isOpenPopup: boolean;
  openPopup: () => void;
  closePopup: () => void;
  togglePopup: () => void;
}

function usePopupHandlers(): PopupHandlers {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const openPopup = useCallback(() => setIsOpenPopup(true), []);
  const closePopup = useCallback(() => setIsOpenPopup(false), []);
  const togglePopup = useCallback(() => setIsOpenPopup(!isOpenPopup), [isOpenPopup]);

  return {
    isOpenPopup,
    openPopup,
    closePopup,
    togglePopup,
  };
}

export default usePopupHandlers;
