import { lazy } from 'react';

export const LoginPage = lazy(() =>
  import('features/login').then(({ LoginPage }) => ({ default: LoginPage })),
);

export const TimeOffBalancePage = lazy(() =>
  import('features/timeOffBalance').then(({ TimeOffBalancePage }) => ({
    default: TimeOffBalancePage,
  })),
);

export const ProfilePage = lazy(() =>
  import('features/profile').then(({ ProfilePage }) => ({
    default: ProfilePage,
  })),
);

export const ContractorsPage = lazy(() =>
  import('features/contractors').then(({ ContractorsPage }) => ({ default: ContractorsPage })),
);

export const PendingRequestsPage = lazy(() =>
  import('features/pendingRequests').then(({ PendingRequestsPage }) => ({
    default: PendingRequestsPage,
  })),
);

export const EditProfilePage = lazy(() =>
  import('features/profile').then(({ EditProfilePage }) => ({
    default: EditProfilePage,
  })),
);

export const ApprovalTreePage = lazy(() =>
  import('features/profile').then(({ ApprovalTreePage }) => ({
    default: ApprovalTreePage,
  })),
);

export const TimeOffReportPage = lazy(() =>
  import('features/timeOffReport').then(({ TimeOffReportPage }) => ({
    default: TimeOffReportPage,
  })),
);

export const RolesAndPermissionsPage = lazy(() =>
  import('features/rolesAndPermissions').then(({ RolesAndPermissionsPage }) => ({
    default: RolesAndPermissionsPage,
  })),
);

export const SurveysPage = lazy(() =>
  import('features/surveys').then(({ SurveysPage }) => ({
    default: SurveysPage,
  })),
);

export const CreateQuestionnairePage = lazy(() =>
  import('features/surveys').then(({ CreateQuestionnairePage }) => ({
    default: CreateQuestionnairePage,
  })),
);

export const CreateEsatFormPage = lazy(() =>
  import('features/surveys').then(({ CreateEsatFormPage }) => ({
    default: CreateEsatFormPage,
  })),
);
export const AnswerEsatPage = lazy(() =>
  import('features/surveys').then(({ AnswerEsatPage }) => ({
    default: AnswerEsatPage,
  })),
);

export const CompleteAnswerEsatPage = lazy(() =>
  import('features/surveys').then(({ CompleteAnswerEsatPage }) => ({
    default: CompleteAnswerEsatPage,
  })),
);

export const OvertimeUndertimePage = lazy(() =>
  import('features/overtimeUndertime').then(({ OvertimeUndertimePage }) => ({
    default: OvertimeUndertimePage,
  })),
);

export const RiskRadarPage = lazy(() =>
  import('features/riskRadar').then(({ RiskRadarPage }) => ({
    default: RiskRadarPage,
  })),
);

export const CompensationsPage = lazy(() =>
  import('features/compensations').then(({ CompensationsPage }) => ({
    default: CompensationsPage,
  })),
);

export const ImportDismissalReasonsPage = lazy(() =>
  import('features/importDismissalReasons').then(({ ImportDismissalReasonsPage }) => ({
    default: ImportDismissalReasonsPage,
  })),
);

export const NineBoxAssessPage = lazy(() =>
  import('features/surveys').then(({ NineBoxAssessPage }) => ({
    default: NineBoxAssessPage,
  })),
);

export const NineBoxResultPage = lazy(() =>
  import('features/surveys').then(({ NineBoxResultPage }) => ({
    default: NineBoxResultPage,
  })),
);

export const NineBoxAssessmentsStatusPage = lazy(() =>
  import('features/surveys').then(({ NineBoxAssessmentsStatusPage }) => ({
    default: NineBoxAssessmentsStatusPage,
  })),
);

export const TimeTrackingPage = lazy(() =>
  import('features/timeTracking').then(({ TimeTrackingPage }) => ({
    default: TimeTrackingPage,
  })),
);

export const TimeTrackingLogDetailsPage = lazy(() =>
  import('features/timeTracking').then(({ LogDetailsPage }) => ({
    default: LogDetailsPage,
  })),
);

export const PaymentRequiredPage = lazy(() =>
  import('features/paymentRequired').then(({ PaymentRequired }) => ({
    default: PaymentRequired,
  })),
);

export const FaqPage = lazy(() =>
  import('features/faq').then(({ FaqPage }) => ({
    default: FaqPage,
  })),
);

export const AppInfoPage = lazy(() =>
  import('features/appInfo').then(({ AppInfoPage }) => ({
    default: AppInfoPage,
  })),
);
