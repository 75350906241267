import { generatePath, useNavigate } from 'react-router-dom';

import { Notification } from 'api';
import { notify } from 'components/notification';
import { useAuth, usePopupHandlers, useProfileQuery, useYaMeRolesQuery } from 'hooks';
import { ROUTE_PATHS } from 'settings';
import { replacePhotoSrcToSmall } from 'utils';

import {
  useNineBoxInformedMutation,
  useNotificationsQuery,
  useRiskRadarLogQuery,
} from '../../hooks';

interface TopNavigationContentHandlers {
  handleSignOut: () => void;
  handleClickOnRiskRadarButton: () => void;
  handleClickNineBoxInformed: (id: string) => void;
  navigateToProfile: () => void;
  togglePopup: () => void;
  closePopup: () => void;
  avatarSrc?: string | null;
  userFullName: string;
  tooltipContent: string;
  isMultipleRoles: boolean;
  isOpenPopup: boolean;
  notifications: Notification[];
}

function useTopNavigationContentHandlers(): TopNavigationContentHandlers {
  const { id, signOut, roles } = useAuth();
  const { data: { photo, firstName, lastName } = {} } = useProfileQuery(id, {
    enabled: Boolean(id),
  });
  const { data: notifications = [], refetch: refetchNotifications } = useNotificationsQuery();
  const navigate = useNavigate();
  const handleSignOut = () => signOut();
  const navigateToProfile = () => {
    const path = generatePath(ROUTE_PATHS.ASSOCIATES.BY_ID, {
      id,
    });

    navigate(path);
  };

  const { data: yaMeRoles } = useYaMeRolesQuery();
  const userRoles = yaMeRoles?.filter(({ identifier }) => roles?.includes(identifier)) || [];
  const { isOpenPopup, togglePopup, closePopup } = usePopupHandlers();

  const { refetch } = useRiskRadarLogQuery({
    enabled: false,
  });
  const { submitNineBoxInformed } = useNineBoxInformedMutation();

  const handleClickOnRiskRadarButton = () => refetch();

  const handleClickNineBoxInformed = (id: string) => {
    submitNineBoxInformed.mutate(
      { id },
      {
        onSuccess() {
          refetchNotifications();
          closePopup();
        },
        onError({ message }) {
          notify({ content: message, type: 'error' });
        },
      },
    );
  };

  return {
    handleSignOut,
    handleClickOnRiskRadarButton,
    handleClickNineBoxInformed,
    navigateToProfile,
    togglePopup,
    closePopup,
    avatarSrc: replacePhotoSrcToSmall(photo || ''),
    userFullName: `${firstName || ''} ${lastName || ''}`,
    tooltipContent: userRoles.map(({ name }) => name).join(' | '),
    isMultipleRoles: userRoles.length > 1,
    isOpenPopup,
    notifications,
  };
}

export default useTopNavigationContentHandlers;
