import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { Resources } from 'api';
import { useFetchContractorDirectory } from 'hooks';

function useResourcesRelativeToQuery(
  associateId: string,
  options?: UseQueryOptions<Resources, Error>,
): UseQueryResult<Resources, Error> {
  const authorisedFetch = useFetchContractorDirectory();

  return useQuery<Resources, Error>(
    ['relative_to_resources', associateId],
    async () => {
      const response = await authorisedFetch(`/resources?relativeTo=${associateId}`, {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useResourcesRelativeToQuery;
