import { useQuery, UseQueryResult } from 'react-query';

import { Notification } from 'api';
import { useFetchContractorDirectory } from 'hooks';

function useNotificationsQuery(): UseQueryResult<Notification[], Error> {
  const authorisedFetch = useFetchContractorDirectory();

  return useQuery<Notification[], Error>('notification', async () => {
    const response = await authorisedFetch('/notification', {
      method: 'GET',
    });

    return await response.json();
  });
}
export default useNotificationsQuery;
