import { colors } from '@atlaskit/theme';
import { CSSProperties } from 'react';
import { toast, ToastContent, ToastOptions, TypeOptions } from 'react-toastify';

interface NotifyParams {
  content: ToastContent;
  type: TypeOptions | undefined;
  options?: ToastOptions;
}

const notifyTypeAppearance = new Map<NotifyParams['type'], CSSProperties>([
  ['info', { backgroundColor: colors.N500 }],
  ['success', { backgroundColor: colors.G400 }],
  ['error', { backgroundColor: colors.R400 }],
  ['warning', { backgroundColor: colors.Y200, color: colors.N700, fill: colors.N700 }],
]);

function notify({ content, type = 'info', options }: NotifyParams) {
  return toast(content, {
    type,
    style: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '2rem',
      color: colors.N0,
      fill: colors.N0,
      ...notifyTypeAppearance.get(type),
    },
    autoClose: 5000,
    ...options,
  });
}

export default notify;
