import { colors } from '@atlaskit/theme';
import { ReactElement, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 16px;
  padding: 0 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  text-decoration: none;
  white-space: nowrap;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const Icon = styled.span`
  margin-right: 16px;
  display: flex;
  align-items: center;
`;

interface NavItemProps {
  to: string;
  children: ReactNode;
  icon: ReactNode;
}

function NavItem({ to, children, icon, ...props }: NavItemProps): ReactElement {
  const location = useLocation();
  const isPathMatch = location.pathname.startsWith(to);

  return (
    <StyledLink
      to={to}
      style={{
        color: isPathMatch ? colors.B400 : colors.N500,
        backgroundColor: isPathMatch ? colors.N20A : colors.N10,
      }}
      {...props}
    >
      <Icon>{icon}</Icon>
      <span>{children}</span>
    </StyledLink>
  );
}

export default NavItem;
