import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { ProjectsResponseSuccess } from 'api';
import { useFetchErp } from 'hooks';

function useProjectsQuery(
  options?: UseQueryOptions<ProjectsResponseSuccess, Error>,
): UseQueryResult<ProjectsResponseSuccess, Error> {
  const authorisedFetch = useFetchErp();

  return useQuery<ProjectsResponseSuccess, Error>(
    'projects',
    async () => {
      const response = await authorisedFetch('/yame/projects', {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useProjectsQuery;
