import { Resources } from 'api';
import { ROUTE_PATHS } from 'settings';
import { permissionsHandler } from 'utils';

export const availableTimeTrackingRoute = (resources: Resources) => {
  const { canLogTime, canApproveTimeSheet, canManageLogPeriods, canManageIssue } =
    permissionsHandler(resources);

  const { TIME_OFF_BALANCE, TIME_TRACKING } = ROUTE_PATHS;

  switch (true) {
    case canLogTime:
      return TIME_TRACKING.LOGS.INDEX;

    case canLogTime || canManageIssue:
      return TIME_TRACKING.PROJECT_TASKS.INDEX;

    case canApproveTimeSheet:
      return TIME_TRACKING.PROJECT_LOGS.INDEX;

    case canManageLogPeriods:
      return TIME_TRACKING.PERIOD_MANAGEMENT.INDEX;

    default:
      return TIME_OFF_BALANCE.INDEX;
  }
};
