import { FileTypes } from 'api';

export const photoFormatRule = /\.(jpg|png)/g;
export const lettersCharactersRule = /^[a-zA-Z\s~`&+,:;=?@#|'<>.^*()%!_№{}[\]/\\"₴€£$-]+$/;
export const lettersAndNumbersCharactersRule =
  /^[a-zA-Z\d\s~`&+,:;=?@#|'<>.^*()%!_№{}[\]/\\"₴€£$-]+$/;
export const currentCityRule = /^[a-zA-Z\s\-]+$/;
export const currentCountryRule = /^[a-zA-Z\s\-]+$/;
export const lettersNumbersCharactersRule = /^[a-zA-Z\d\s$&+,№:~`’;[\]{}=?\\"/@#|'<>.^*()%!-]+$/;
export const childNameRule = /^[a-zA-Z\-]+$/;
export const roleNameRule = /^[a-zA-Z0-9.,\s\-]+$/;
export const roleDescriptionRule = /^[a-zA-Z0-9.,\s\-]+$/;
export const latinLettersNumberSpecialSymbols =
  /^[a-zA-Z\d\s~`&+,:;=?@#|'<>.^*()%!_№{}[\]/\\"₴€£$-]+$/;
export const surveyAnswerRule =
  /^[a-zA-ZА-ЯҐЄЁІЇа-яґєёії\d\s~`&+,:;=?@#|'<>.^*()%!_№{}[\]/\\"₴€£$-]+$/;
export const timeOfBalanceManualManageRule =
  /^[a-zA-Z0-9\u0400-\u04FF,.@!#$%&'*+-/\\=?^_`{|}~[\] ]*$/;
export const overtimeUndertimeSumRule =
  /^(?!(\.0+)?(e|$))(-)?(0|[1-9]\d*)?((\.|,)\d{1,2})?(?:\d)(e-?(0|[1-9]))?$/;

export const SUPPORTED_FILE_FORMATS = ['image/jpeg', 'image/png'];
export const SUPPORTED_FILE_FORMATS_REQUEST = [FileTypes.jpeg, FileTypes.png, FileTypes.pdf];
export const MAX_FILE_SIZE = 5;
export const MIN_PHOTO_HEIGHT = 780;
export const MIN_PHOTO_WIDTH = 780;
