import { ReactElement } from 'react';

import { useAuth } from 'hooks';

interface ErrorPageProps {
  onReset?: () => void;
}

export default function SomethingWentWrongPage({ onReset }: ErrorPageProps): ReactElement {
  const { signOut } = useAuth();

  return (
    <div title="Something went wrong">
      <h1>Something went wrong</h1>
      <button
        onClick={() => {
          signOut();
          onReset?.();
        }}
      >
        go to login page
      </button>
    </div>
  );
}
