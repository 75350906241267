import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { Resources } from 'api';
import { useFetchContractorDirectory } from 'hooks';

function useResourcesByIdQuery(
  associateId: string,
  options?: UseQueryOptions<Resources, Error>,
): UseQueryResult<Resources, Error> {
  const authorisedFetch = useFetchContractorDirectory();

  return useQuery<Resources, Error>(
    ['resources_id', associateId],
    async () => {
      const response = await authorisedFetch(`/resources/id/${associateId}`, {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useResourcesByIdQuery;
