const parseFilterValue = (filters: { [key: string]: string[] | number[] | string }): string => {
  let params = '';

  Object.keys(filters).forEach((key) => {
    const filterValues = filters[key];

    if (!filterValues.length) return;

    if (Array.isArray(filterValues)) {
      filterValues.forEach((el) => (params += `${key}=${el}&`));
    }

    if (typeof filters[key] === 'string') {
      params += `${key}=${filters[key]}&`;
    }
  });

  return '?' + new URLSearchParams(params).toString();
};

export default parseFilterValue;
