import { PageLayout, Main, Content, TopNavigation, LeftSidebar } from '@atlaskit/page-layout';
import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { SuspenseWrapper } from 'routes';

import { TopNavigationContent, Page, LeftSidebarContent } from './components';

const TopNavigationContainer = styled.div`
  z-index: 3;
`;

function Layout(): ReactElement {
  return (
    <PageLayout>
      <Content>
        <TopNavigationContainer>
          <TopNavigation height={68}>
            <TopNavigationContent />
          </TopNavigation>
          <LeftSidebar width={255}>
            <LeftSidebarContent />
          </LeftSidebar>
        </TopNavigationContainer>

        <Main>
          <Page>
            <SuspenseWrapper>
              <Outlet />
            </SuspenseWrapper>
          </Page>
        </Main>
      </Content>
    </PageLayout>
  );
}

export default Layout;
