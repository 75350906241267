import React from 'react';
import { render } from 'react-dom';
import '@atlaskit/css-reset';

import { favicon, logo } from 'assets';
import { indexHtmlDictionary } from 'indexHtmlDictionary';

import App from './App';
import reportWebVitals from './reportWebVitals';

function initializeApp() {
  const { title } = indexHtmlDictionary;
  const head = document.querySelector('head');

  if (head) {
    const titleElement = document.createElement('title');
    const logoElement = document.createElement('link');
    const faviconElement = document.createElement('link');
    const metaElement = document.createElement('meta');

    titleElement.textContent = title;

    logoElement.rel = 'apple-touch-icon';
    logoElement.href = logo;

    faviconElement.rel = 'icon';
    faviconElement.href = favicon;

    metaElement.name = 'description';
    metaElement.content = title;

    [titleElement, logoElement, faviconElement, metaElement].forEach((e) =>
      document.head.appendChild(e),
    );
  }
}

initializeApp();

render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
