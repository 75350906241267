import { Options } from 'api';

export default function sortArraysOfObjectsByValue(
  a: Options<string | number, string>,
  b: Options<string | number, string>,
) {
  const nameA = a.label.toUpperCase();
  const nameB = b.label.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
}
