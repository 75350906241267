export default function countTheNumberOfSelectedFilters<
  T extends { [K in keyof T]: string[] | unknown },
>(filters: T): number {
  let count = 0;

  for (const value of Object.values(filters)) {
    if (Array.isArray(value) && value.length > 0) {
      count++;
    }
  }

  return count;
}
