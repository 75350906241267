import jwt_decode from 'jwt-decode';

export function tokensTimeToLiveLeft(token: string): number {
  const { exp } = jwt_decode(token);

  return exp * 1000 - Date.now(); //ms
}

export function isTokenExpired(token: string): boolean {
  try {
    return tokensTimeToLiveLeft(token) <= 0;
  } catch {
    return true;
  }
}
