import { colors } from '@atlaskit/theme';
import jwt_decode from 'jwt-decode';
import styled from 'styled-components/macro';

import { localStorageAccessKey } from 'api';

import { getApps } from '../constants';

const AppSwitcherTitle = styled.div`
  color: ${colors.N500};
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  padding: 16px 16px 6px 16px;
`;

const LinkWrapper = styled.a`
  padding: 8px 16px 8px 13px;
  display: flex;
  gap: 12px;
  justify-content: start;
  align-items: center;
  border-left: 2px solid transparent;

  &:hover {
    background-color: rgba(12, 102, 228, 0.1);
    border-left: 2px solid #0c66e4;
    text-decoration: none;
  }
`;

const AppTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: ${colors.N800};
  margin-bottom: 4px;
`;

const AppSubtitle = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  color: #626f86;
`;

export const AppSwitcherContent = () => {
  const token = localStorage.getItem(localStorageAccessKey);
  const { internalRoles } = jwt_decode(token);
  const apps = getApps(internalRoles);

  return (
    <div style={{ width: '100%', minWidth: '360px', padding: '6px 0' }}>
      <AppSwitcherTitle>YOUR APPS</AppSwitcherTitle>

      {apps.map(({ logo, link, title, subTitle, isVisible }) =>
        isVisible === false ? null : (
          <LinkWrapper href={link} key={title}>
            <img width={24} height={24} src={logo} alt="logo" />
            <div>
              <AppTitle>{title}</AppTitle>
              <AppSubtitle>{subTitle}</AppSubtitle>
            </div>
          </LinkWrapper>
        ),
      )}
    </div>
  );
};
