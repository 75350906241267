import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { RequestToApproveResponseSuccess } from 'api';
import { useFetchErp } from 'hooks';

function useRequestsToApproveQuery<SelectedData = RequestToApproveResponseSuccess[]>(
  userId: string,
  options?: UseQueryOptions<RequestToApproveResponseSuccess[], Error, SelectedData>,
): UseQueryResult<SelectedData, Error> {
  const authorisedFetch = useFetchErp();

  return useQuery<RequestToApproveResponseSuccess[], Error, SelectedData>(
    ['pendingRequests', userId],
    async () => {
      const response = await authorisedFetch(`/days_off/requests_to_approve?userID=${userId}`, {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useRequestsToApproveQuery;
