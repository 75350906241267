import { getMonth, getYear } from 'date-fns';

export default function isLastMonth(date = ''): boolean {
  const inputMonth = getMonth(new Date(date));
  const inputYear = getYear(new Date(date));

  const currentMonth = getMonth(new Date());
  const currentYear = getYear(new Date());

  return inputYear < currentYear || (inputYear === currentYear && inputMonth < currentMonth);
}
