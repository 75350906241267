import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { RequestsToReportResponseSuccess } from 'api';
import { useFetchErp } from 'hooks';

function useDaysOffRequestsToReportQuery(
  filters?: string,
  options?: UseQueryOptions<RequestsToReportResponseSuccess[], Error>,
): UseQueryResult<RequestsToReportResponseSuccess[], Error> {
  const authorisedFetch = useFetchErp();

  return useQuery<RequestsToReportResponseSuccess[], Error>(
    'daysOffRequestsToReport',
    async () => {
      const response = await authorisedFetch(`/days_off/requests/to_report${filters}`, {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useDaysOffRequestsToReportQuery;
