import { DaysOffInfo, RequestType, TimeOffBalance, timeOfBalanceTitle } from 'api';

export default function createTimeOffBalance(daysOffInfo?: DaysOffInfo | null) {
  let timeOffBalance: TimeOffBalance[] = [];

  const lastYearVacation = daysOffInfo?.paidVacationLastYear
    ? {
        title: timeOfBalanceTitle.paid_vacation_last_year,
        type: RequestType.paidVacation,
        available: daysOffInfo?.paidVacationLastYear.left,
        used: daysOffInfo?.paidVacationLastYear.used,
      }
    : {};
  const nextYearVacation = daysOffInfo?.paidVacationNextYear
    ? {
        title: timeOfBalanceTitle.paid_vacation_next_year,
        type: RequestType.paidVacation,
        available: daysOffInfo?.paidVacationNextYear.left,
        used: daysOffInfo?.paidVacationNextYear.used,
      }
    : {};

  const sickLeaves = {
    title: timeOfBalanceTitle.undocumented_sick_leave,
    type: RequestType.undocumentedSickLeave,
    available: daysOffInfo?.undocumentedSickLeaves?.left,
    used: daysOffInfo?.undocumentedSickLeaves?.used,
  };

  if (daysOffInfo) {
    timeOffBalance = [
      {
        title: timeOfBalanceTitle.paid_vacation,
        type: RequestType.paidVacation,
        available: daysOffInfo.paidVacation?.left,
        used: daysOffInfo.paidVacation?.used,
      },
      nextYearVacation,
      lastYearVacation,
      sickLeaves,
      {
        title: timeOfBalanceTitle.documented_sick_leave,
        type: RequestType.documentedSickLeave,
        available: daysOffInfo.documentedSickLeaves?.left,
        used: daysOffInfo.documentedSickLeaves?.used,
      },
      {
        title: timeOfBalanceTitle.unpaid_vacation,
        type: RequestType.unpaidVacation,
        available: 0,
        used: daysOffInfo.unpaidVacationUsed,
      },
      {
        title: timeOfBalanceTitle.additional_vacation,
        type: RequestType.additionalVacation,
        available: 0,
        used: daysOffInfo.additionalDaysOffUsed,
      },
    ];
  }
  return timeOffBalance;
}
