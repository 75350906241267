import jwt_decode from 'jwt-decode';

import { read } from 'utils';

import {
  localStorageAccessKey,
  localStorageResourcesAccessKey,
  YA_ME_INTERNAL_SYSTEM_CODE,
} from './constants';
import { isTokenExpired } from './helpers';
import { Resources } from './types';

export enum AuthActions {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

export interface AuthState {
  isAuth: boolean;
  token: string | null;
  roles: string[] | null;
  resources: Resources;
  id: string;
}

type Action =
  | {
      type: AuthActions.LOGIN;
      token: AuthState['token'];
      roles: string[];
      resources: Resources;
      id: AuthState['id'];
    }
  | { type: AuthActions.LOGOUT };

const token = read<string>(localStorageAccessKey);
const resources = read<Resources>(localStorageResourcesAccessKey);
const isTokenValid = !(!token || isTokenExpired(token));

export const initialAuthState = {
  isAuth: isTokenValid,
  token: isTokenValid ? token : null,
  roles: isTokenValid ? jwt_decode(token).internalRoles[YA_ME_INTERNAL_SYSTEM_CODE] : null,
  resources: isTokenValid && resources ? resources : null,
  id: isTokenValid ? jwt_decode(token).id : null,
};

export function authReducer(state: AuthState, action: Action) {
  switch (action.type) {
    case AuthActions.LOGIN:
      return {
        ...state,
        isAuth: true,
        token: action.token,
        roles: action.roles,
        resources: action.resources,
        id: action.id,
      };
    case AuthActions.LOGOUT:
      return { ...state, isAuth: false, token: null, roles: null, resources: null, id: '' };
    default:
      return state;
  }
}
