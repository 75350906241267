import { OvertimeUndertimeResponseSuccess, Resources } from 'api';
import useOvertimeUndertimeRequestQuery from 'features/overtimeUndertime/overtimeUndertimePage/hooks/useOverimeUndertimeRequestQuery';
import { useAuth, useProfileQuery, useRequestsToApproveQuery } from 'hooks';
import { getPreviousRequestDate } from 'utils/getPreviousRequestDate';

interface LeftSidebarContentHandlers {
  unresolvedOvertimeRequestsCount: number;
  unresolvedRequestsCount: number;
  resources: Resources;
}

function useLeftSidebarContentHandlers(): LeftSidebarContentHandlers {
  const { resources, id } = useAuth();
  const { data: { firstName, lastName } = {} } = useProfileQuery(id, {
    enabled: !!id,
  });

  const { year, month } = getPreviousRequestDate();

  const { data: unresolvedRequestsCount = 0 } = useRequestsToApproveQuery<number>(id, {
    enabled: Boolean(resources?.pending_requests),
    select: (data) =>
      data.filter(
        ({ status, approvals }) =>
          (status === 'in_progress' || status === 'new') &&
          approvals.some(
            ({ approver, status }) => approver.cdID === id && status === 'in_progress',
          ),
      ).length,
  });

  const { data: overtimeUndertimeRequest } = useOvertimeUndertimeRequestQuery(
    {
      year,
      month,
    },
    {
      enabled: !!resources?.compensations,
    },
  );

  const handleUnresolvedOvertimeRequestsCount = (
    data: OvertimeUndertimeResponseSuccess['data'],
  ): number =>
    data.filter(
      ({ status, approver }) => status === 'reassigned' && approver === `${firstName} ${lastName}`,
    ).length;

  return {
    unresolvedOvertimeRequestsCount: handleUnresolvedOvertimeRequestsCount(
      overtimeUndertimeRequest?.data || [],
    ),
    unresolvedRequestsCount,
    resources,
  };
}

export default useLeftSidebarContentHandlers;
