import { isWithinInterval } from 'date-fns';

import { requestTypeLabel } from 'api';

import { Contractor, DaysOffDuration, RequestToApproveResponseSuccess } from '../api/types';
import { notify } from '../components/notification';

interface RestrictLeaveRequestProps {
  startDate: string;
  endDate: string;
  requestedLeaves: RequestToApproveResponseSuccess[];
  requestId: string;
  longTermLeave?: { startDate: string; endDate: string };
  typeOfLeaveHistory?: Contractor['typeOfLeaveHistory'];
}
function restrictLeaveRequest({
  startDate,
  endDate,
  requestedLeaves,
  requestId,
  longTermLeave,
  typeOfLeaveHistory,
}: RestrictLeaveRequestProps): boolean {
  if (!startDate || !endDate) {
    return false;
  }

  const requestedLeaveCrossRanges = requestedLeaves.filter(
    ({ from, to, id, status, duration }) =>
      id !== requestId &&
      status !== 'declined' &&
      status !== 'canceled' &&
      duration !== DaysOffDuration.HalfDay &&
      (isWithinInterval(new Date(from), {
        start: new Date(startDate),
        end: new Date(endDate),
      }) ||
        isWithinInterval(new Date(to), {
          start: new Date(startDate),
          end: new Date(endDate),
        })),
  );

  const hasRequestedTypeOfLeaveHistoryCrossRanges = typeOfLeaveHistory?.filter(
    ({ period }) =>
      isWithinInterval(new Date(period.startDate), {
        start: new Date(startDate),
        end: new Date(endDate),
      }) ||
      isWithinInterval(new Date(period.endDate), {
        start: new Date(startDate),
        end: new Date(endDate),
      }),
  );

  const hasRequestedLongTermLeaveCrossRanges =
    !!longTermLeave &&
    (isWithinInterval(new Date(longTermLeave.startDate), {
      start: new Date(startDate),
      end: new Date(endDate),
    }) ||
      isWithinInterval(new Date(longTermLeave.endDate), {
        start: new Date(startDate),
        end: new Date(endDate),
      }));

  switch (true) {
    case !!requestedLeaveCrossRanges.length:
      requestedLeaveCrossRanges.forEach(({ type, from, to }) =>
        notify({
          content: `There is other request for this date: ${requestTypeLabel[type]} ${from} - ${to}`,
          type: 'error',
        }),
      );

      return true;

    case !!hasRequestedTypeOfLeaveHistoryCrossRanges?.length:
      hasRequestedTypeOfLeaveHistoryCrossRanges?.forEach(
        ({ typeOfLeave, period: { startDate, endDate } }) =>
          notify({
            content: `Long term leave set for this date: ${typeOfLeave} ${startDate} - ${endDate}`,
            type: 'error',
          }),
      );

      return true;

    case hasRequestedLongTermLeaveCrossRanges:
      notify({
        content: `Long term leave set for this date`,
        type: 'error',
      });

      return true;

    default:
      return false;
  }
}

export default restrictLeaveRequest;
