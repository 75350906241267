import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { useFetchContractorDirectory } from 'hooks';

function useRiskRadarLogQuery(
  options?: UseQueryOptions<unknown, Error>,
): UseQueryResult<unknown, Error> {
  const authorisedFetch = useFetchContractorDirectory();

  return useQuery<unknown, Error>(
    'risk-radar-log',
    async () => {
      const response = await authorisedFetch('/risk_radar/log', {
        method: 'GET',
      });

      return response;
    },
    options,
  );
}

export default useRiskRadarLogQuery;
