import Badge from '@atlaskit/badge';
import Button from '@atlaskit/button';
import NotificationIcon from '@atlaskit/icon/glyph/notification';
import { Popup } from '@atlaskit/popup';
import { typography } from '@atlaskit/theme';
import { ReactElement } from 'react';

import { Notification, NotificationType, SurveysTabsEnum } from 'api';
import { ROUTE_PATHS } from 'settings';
import { Divider, Heading } from 'styles';

import * as S from './styled';

interface NotificationsProps {
  isOpenPopup: boolean;
  notifications: Notification[];
  togglePopup: () => void;
  closePopup: () => void;
  handleClickOnRiskRadarButton: () => void;
  handleClickNineBoxInformed: (id: string) => void;
}

function Notifications({
  isOpenPopup,
  notifications,
  togglePopup,
  closePopup,
  handleClickOnRiskRadarButton,
  handleClickNineBoxInformed,
}: NotificationsProps): ReactElement {
  return (
    <Popup
      isOpen={isOpenPopup}
      onClose={closePopup}
      placement="bottom-end"
      content={() => (
        <S.ContentWrapper>
          <Heading mixin={typography.h600} style={{ marginTop: '0.5rem' }}>
            Notifications
          </Heading>
          <Divider />
          {notifications.map(({ data, title, description, type }, idx) => (
            <S.Container key={idx}>
              <Heading mixin={typography.h400} style={{ marginTop: 0 }}>
                {title}
              </Heading>
              <Heading mixin={typography.h200} style={{ margin: '0.875rem 0' }}>
                <S.DescriptionContainer>{description}</S.DescriptionContainer>
              </Heading>

              {type === NotificationType.Esat ? (
                <>
                  <Button style={{ marginRight: '1rem' }} appearance="subtle" onClick={closePopup}>
                    Maybe later
                  </Button>

                  <S.ButtonLink
                    href={`${ROUTE_PATHS.SURVEYS.INDEX}/${data?.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Answer now
                  </S.ButtonLink>
                </>
              ) : null}

              {type === NotificationType.RiskRadar ? (
                <>
                  <Button style={{ marginRight: '1rem' }} appearance="subtle" onClick={closePopup}>
                    Update later
                  </Button>

                  <S.ButtonLink
                    href={ROUTE_PATHS.RISK_RADAR.INDEX}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleClickOnRiskRadarButton}
                  >
                    Update now
                  </S.ButtonLink>
                </>
              ) : null}

              {type === NotificationType.NineBoxAssess ? (
                <>
                  <Button
                    style={{ marginRight: '1rem' }}
                    appearance="subtle"
                    onClick={() => handleClickNineBoxInformed(data?.id || '')}
                  >
                    Later
                  </Button>

                  <S.ButtonLink
                    href={`${ROUTE_PATHS.SURVEYS.INDEX}?tab=${SurveysTabsEnum.NineBox}`}
                    onClick={() => handleClickNineBoxInformed(data?.id || '')}
                  >
                    Assess now
                  </S.ButtonLink>
                </>
              ) : null}

              {type === NotificationType.NineBoxReminder ? (
                <Button
                  style={{ marginRight: '1rem' }}
                  appearance="subtle"
                  onClick={() => handleClickNineBoxInformed(data?.id || '')}
                >
                  OK
                </Button>
              ) : null}
            </S.Container>
          ))}
        </S.ContentWrapper>
      )}
      trigger={(triggerProps) => (
        <Button
          {...triggerProps}
          iconBefore={<NotificationIcon label="NotificationIcon" />}
          appearance="subtle"
          isSelected={isOpenPopup}
          onClick={togglePopup}
          isDisabled={!notifications.length}
        >
          {notifications.length ? (
            <S.BadgeStyled>
              <Badge appearance="important">{notifications.length}</Badge>
            </S.BadgeStyled>
          ) : null}
        </Button>
      )}
    />
  );
}

export default Notifications;
