import Lozenge from '@atlaskit/lozenge';
import { ThemeProps, typography } from '@atlaskit/theme';
import Tooltip from '@atlaskit/tooltip';
import { ReactElement } from 'react';
import styled from 'styled-components/macro';

const Heading = styled.div<{ mixin: () => ThemeProps }>`
  ${(props) => props.mixin()};
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0.25rem;
  line-height: 1.25rem;
`;

const Wrapper = styled.div`
  margin-left: 0.15rem;
`;

interface InfoCurrentUserProps {
  userFullName: string;
  tooltipContent: string;
  isMultipleRoles: boolean;
}

function InfoCurrentUser({
  userFullName,
  tooltipContent,
  isMultipleRoles,
}: InfoCurrentUserProps): ReactElement {
  return (
    <Wrapper>
      <Heading mixin={typography.h400}>{userFullName}</Heading>
      {isMultipleRoles ? (
        <Tooltip content={tooltipContent}>
          <Lozenge appearance="inprogress" isBold>
            Multiple role
          </Lozenge>
        </Tooltip>
      ) : (
        <Lozenge appearance="inprogress" isBold>
          {tooltipContent}
        </Lozenge>
      )}
    </Wrapper>
  );
}

export default InfoCurrentUser;
