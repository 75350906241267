// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export default function checkIsFiltersSelected(values: any): boolean {
  const keys = Object.keys(values);
  return keys.some((key) => {
    const value = values[key];
    if (Array.isArray(value)) {
      return value.length !== 0;
    } else if (typeof value === 'string') {
      return value !== '';
    } else {
      return false;
    }
  });
}
