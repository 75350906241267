import { colors } from '@atlaskit/theme';
import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  width: 34rem;
  max-height: 86vh;
  padding: 1.5rem;
  background-color: ${colors.N0};
  overflow: auto;
`;
export const BadgeStyled = styled.div`
  position: absolute;
  top: -16px;
  right: 5px;
`;
export const Container = styled.div`
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0.5rem;
  }
`;
export const ButtonLink = styled.a`
  padding: 8px 12px;
  font-family: inherit;
  font-weight: 500;
  background-color: ${colors.B400};
  color: ${colors.N10};
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    color: ${colors.N10};
    background-color: ${colors.B300};
    text-decoration: none;
  }
`;
export const DescriptionContainer = styled.div`
  white-space: pre-line;
`;
